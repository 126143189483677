import React from "react";
import logo from './logo.svg';
import img1 from './img/img-1.png';
import img2 from './img/img-2.png';
import img3 from './img/img-3.png';
import img4 from './img/img-4.png';
import stone from './img/stone.png';
import { Flex, Text, Divider } from '@aws-amplify/ui-react';


const Body = () => {
    return (
        <div>
            <div class="container">
                <h1 class="text-body-emphasis">RockDrop Revolutionizing Stone Delivery</h1>
                <p></p>
                <div class="row">
                    <div class="col">
                        <figure><img src={img1} class="img-fluid"></img></figure>
                        <h3 class="text-body-emphasis">Unleash the Power of Rock Delivery</h3>
                        <p class="fs-5">RockDrop's mobile app connects truck drivers with customers for on-demand stone delivery, changing the game in logistics.</p>
                    </div>
                    <div class="col">
                        <figure><img src={img2} class="img-fluid"></img></figure>
                        <h3 class="text-body-emphasis">Stone Quarries Rejoice with RockDrop</h3>
                        <p class="fs-5">List your stone products on RockDrop's platform for increased visibility and seamless distribution to customers.</p>
                    </div>
                    <div class="col">
                        <figure><img src={img3} class="img-fluid"></img></figure>
                        <h3 class="text-body-emphasis">Real-Time Tracking for Stones</h3>
                        <p class="fs-5">Track your stone delivery via GPS in real-time with RockDrop's innovative technology.</p>
                    </div>
                    <div class="col">
                        <figure><img src={img4} class="img-fluid"></img></figure>
                        <h3 class="text-body-emphasis">Fast Payments, Happy Drivers</h3>
                        <p class="fs-5">RockDrop guarantees fast and secure payment processing, giving peace of mind and fair compensation.</p>
                    </div>
                </div>
            </div>

            <div class="container even-container">
                <h1 class="text-body-emphasis">Our Mission: Rock On!</h1>

                <div class="row">
                    <div class="col">
                        <figure><img src={stone} class="img-fluid"/></figure>
                    </div>
                    <div class="col">
                        <p class="fs-5">At RockDrop, we're not just delivering stones, we're revolutionizing the way stones move. Our mobile app connects drivers, customers, and quarries in a seamless dance of stone delivery.
                        With a focus on efficiency and reliability, we're here to shake up the logistics world with our on-demand stone delivery platform. Say goodbye to traditional methods and embrace the future of stone transportation with RockDrop!
                        Join us in this epic journey as we pave the way for a new era of rock-solid logistics. Let's rock on together!</p>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Body;