import React from "react";
import ReactDOM from "react-dom";
import tejasBlack from './img/tejas-black.jpg';
import './App.css';


function Page() {
    return (
        <div>
        <section class="hero">
            <div class="hero-content">
                <h1 class="tagline">Unleash the Power of Rock Delivery</h1>
                <button class="get-started">Get Started</button>
            </div>


            <div>
                <h2>Featured Rock and Stone Marketplace</h2>
                <div class="rock-cards">
                    <div class="rock-card">
                        <figure><img src={tejasBlack} class="img-fluid"></img></figure>
                        <h3>Tejas Black</h3>
                        <p>Crushed basalt averaging a 2-3″ diameter. Commonly used in garden beds and other aesthetic purposes.
                           Sometimes referred to as Texas Black Star or crushed basalt.</p>
                        <span class="price">$99.99</span>
                    </div>
                </div>
            </div>
        </section>
        </div>
        
  
    );
  }
  
  export default Page;