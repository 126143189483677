import React, { useState, useEffect } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import getAuthenticatedUser from '../useuserstatus.js';

import Header from "../Header.js";
import Body from "../Body.js";
import Footer from "../Footer.js";
import Page from '../Page.js';

import { Amplify } from 'aws-amplify';


export default function Root() {
    const [user, setUser] = useState(null);
    const [session, setSession] = useState(null);
  
    useEffect(() => {
      fetchUser();
    }, []);
  
    const fetchUser = async () => {
      const {user, session} = await getAuthenticatedUser();
      setUser(user);
      setSession(session);
    };

    return (
        <div>
        <Header />
        {user ? (
            <Page />  
        ) : (
            <Body />
        )}
        <Footer />
        </div>

    );
}