// useuserstatus.ts
import React, { useState, useEffect } from 'react';
import {fetchAuthSession, getCurrentUser} from '@aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

/**
 * userUserStatus is a react hook that tracks the user's login status and provides a "isLoggedIn" flag that can be checked in code.
 */

const getAuthenticatedUser = async () => {
  const {
    username,
    userId,
    signInDetails
  } = await getCurrentUser();

  const {
    tokens: session
  } = await fetchAuthSession();
  
  return {
    username,
    session
  };
}

export default getAuthenticatedUser;

