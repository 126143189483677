import React, { useState, useEffect } from "react";
import getAuthenticatedUser from './useuserstatus.js';
import { Link, useNavigate } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import { Button, Authenticator } from '@aws-amplify/ui-react';
import { Flex, Text, Divider } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';


const Header = () => {

    const [user, setUser] = useState(null);
    const [session, setSession] = useState(null);
  
    useEffect(() => {
      fetchUser();
    }, []);
  
    const fetchUser = async () => {
      const {user, session} = await getAuthenticatedUser();
      setUser(user);
      setSession(session);
    };


  return (
    <div class="header">
        <nav class="navbar navbar-expand-lg navbar-default bg-default">
            
            <div class="container h4">
            <a>RockDrop</a>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" href="#">Vendor</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Driver</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Customer</a>
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">

                    <Link className="nav-link" to="/login">
                        <Button variation="primary" colorTheme="success">Log In</Button>
                    </Link>


                        
                    </li>
                    <li class="nav-item">
                        <div class="dropdown">
                            <Button variation="primary" colorTheme="info">Sign Up</Button>
                            <div class="dropdown-content">
                                <a href="#">Vendor</a>
                                <a href="#">Driver</a>
                                <a href="#">Customer</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            </div>
        </nav>
        <Divider />
    </div>
  );
};

export default Header;